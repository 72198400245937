import PropTypes from 'prop-types';
import {
    Box,
    List,
    Stack,
    Typography,
    Avatar,
    IconButton,
    SvgIcon,
    Tooltip,
} from '@mui/material';
import React from "react";
import { useNavigate } from "react-router";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import UserOnlineDot from "../../../modules/core/UserOnlineDot";
import MessagesIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import SmartPopper from "../../../elements/smart-popper";
import DelayedCircularProgress from "../../../elements/delayed-circular-progress";

export const ContactsPopover = ({popover}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.account.user);
    const users = useSelector((state) => state.users.users);
    const { isFlying, isMobile } = useIsMobile();
    const usersInitialized = useSelector((state) => state.users.initialized);

    const viewAll = () => {
        navigate('/groon/organization');
    }

    const openUser = (user) => {
        navigate('/groon/profile/' + user?.id);
        popover.handleClose();
    }

    return (
        <SmartPopper
            popover={popover}
            placement={isMobile ? "bottom" : (isFlying ? "left-end" : "bottom-end")}
            minWidth={220}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                p={2}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("core.contacts")}
                </Typography>
                <Tooltip title={t("common.to_all_contacts")} placement="bottom">
                    <IconButton
                        onClick={viewAll}
                        size="small"
                        color="inherit"
                    >
                        <SvgIcon>
                            <MessagesIcon/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Stack>
            <Box sx={{p: 0}}>
                {users.filter(u => u.id !== user.id).length > 0 ? (
                    <List sx={{overflow: 'auto', maxHeight: 390, p: 1}}>
                        {users.filter(u => u.id !== user.id).sort((a, b) => a.interactions > b.interactions ? -1 : 1).map((user, i) => (
                            <Stack
                                component="li"
                                direction="row"
                                alignItems="center"
                                key={'contact-' + i}
                                onClick={() => openUser(user)}
                                spacing={2}
                                sx={{
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    p: 1,
                                    '&:hover': {
                                        backgroundColor: 'action.hover'
                                    },
                                }}
                            >
                                <Avatar
                                    src={user?.avatar?.view || null}
                                    sx={{
                                        height: 35,
                                        width: 35
                                    }}
                                />
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflow: 'hidden'
                                    }}
                                >
                                    <Typography
                                        noWrap
                                        variant="subtitle1"
                                    >
                                        {user?.name}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        variant="subtitle2"
                                        color="text.secondary"
                                    >
                                        {user?.position}
                                    </Typography>
                                </Box>
                                <UserOnlineDot
                                    userId={user?.id}
                                />
                            </Stack>
                        ))}
                    </List>
                ) : (
                    <div style={{
                        height: '400px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        {usersInitialized ? (
                            <Typography variant="subtitle2" color="textSecondary">
                                {t("core.no_contacts")}
                            </Typography>
                        ) : (
                            <DelayedCircularProgress size={20} />
                        )}
                    </div>
                )}
            </Box>
        </SmartPopper>
    )

};

ContactsPopover.propTypes = {
    popover: PropTypes.object,
};
