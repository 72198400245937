import React from "react";
import {useTranslation} from "react-i18next";
import {
    IconButton,
    ListItem,
    ListItemButton,
    Stack,
    Typography
} from "@mui/material";
import OverviewList from "../../elements/overview-list";
import {useNavigate} from "react-router-dom";
import OnIcon from "../../elements/icon";
import SmartPopper from "../../elements/smart-popper";
import useOmniaApi from "../../../hooks/use-omnia-api";
import PropTypes from "prop-types";
import {useSettings} from "../../../hooks/use-settings";
import {useIsMobile} from "../../../hooks/use-is-mobile";

function HistoryPopover({popover, onClick, posPercentage, ...other}){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { del } = useOmniaApi();
    const { isFlying } = useIsMobile();
    const settings = useSettings();

    const handleOpenChat = (chatId) => {
        navigate('/groon/ai/chats/' + chatId);
        popover.handleClose();
        onClick?.();
    }

    const handleRemoveChat = (chatId) => {
        if(window.confirm(t('notify.are_you_sure'))){
            del('core/assistant-chats', chatId).then(() => {
                navigate('/groon/ai');
            })
        }
    }

    return (
        <SmartPopper
            popover={popover}
            placement={((settings.layout === 'vertical') || isFlying) ? 'right' : (posPercentage > 0.5 ? 'right-end' : 'right-start')}
            minWidth={250}
            {...other}
        >
            <OverviewList
                endpoint='core/assistant-chats'
                query={{assistant__isnull: false, ordering: '-last_message_at'}}
                pageSize={50}
                maxHeight={600}
                renderItem={(item, index) => (
                    <ListItem
                        sx={{p: 0}}
                        key={index}
                    >
                        <ListItemButton onClick={() => handleOpenChat(item?.id)}>
                            <Stack
                                sx={{width: '100%'}}
                                key={index}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{width: '100%'}}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{width: '100%'}}
                                    >
                                        <Stack sx={{width: '100%'}}>
                                            <Typography color={item?.kind || 'text.primary'} variant="h6">{item?.name}</Typography>
                                            <Typography noWrap variant="caption" color="text.secondary">{item?.assistant_name}</Typography>
                                        </Stack>
                                    </Stack>
                                    <IconButton onClick={() => handleRemoveChat(item?.id)}>
                                        <OnIcon iconName="Trash01" />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </ListItemButton>
                    </ListItem>
                )}
            />
        </SmartPopper>
    )

}

HistoryPopover.propTypes = {
    popover: PropTypes.object,
    posPercentage: PropTypes.number,
    onClick: PropTypes.func
}

export default HistoryPopover;